body {
  background: #000;
}

.App {
  text-align: center;
}

.App video {
  height: 100%;
  width: 100%;
  background: #000;
}

.App-logo {
  height: 20px;
  pointer-events: none;
  margin: 10px;
}

.App-header {
  background-color: #A91401;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}